import React, { useState } from 'react';
import { postData } from '../../../requests/requests';
import './ForgotPassword.scss';
import environment from '../../../enviroment/enviroment';
import Loading from '../../utils/loading/Loading';
import Error from '../../components/error/Error'
import Success from '../../components/success/Success'
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const resetPassword = async () => {
        setLoading(true);
        setError('');
        setSuccess(false);

        const url = environment.auth0.url_api;
        const data = {
            "client_id": environment.auth0.client_id,
            "email": username,
            "connection": "Username-Password-Authentication"
        };

        try {
            await postData(url, '/dbconnections/change_password', data);
            setSuccess(true)
            
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username) {
            resetPassword();
        } else {
            setError("Por favor, ingresa tu correo.");
        }
    };

    return (
        <div className="forgot-container">
            {loading && <Loading />}
            {!loading && (
                <>
                    <div className="forgot-container-buttons">
                        {success&&<Success onAction={()=>navigate('/')} message='Revisa la bandeja de tu correo para continuar.' ></Success>}
                        {error&&<Error></Error>}
                        <h1>FIDE<span>YA</span></h1>
                        <div className="forgot-container-buttons-cta">
                            <form className="forgot-container-buttons-cta-form" onSubmit={handleSubmit}>
                                <h3>¿Olvidó su contraseña?</h3>
                                <p>Ingresa el correo electrónico asociado a tu cuenta para recibir un correo con las indicaciones para recuperar tu contraseña.</p>
                                <div className="forgot-container-buttons-cta-form-input">
                                    <input
                                        type="email"
                                        id="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder='Correo'
                                        required />
                                </div>
                                <button type="submit" className="forgot-container-buttons-cta-form-button">Cambiar contraseña</button>
                                {error && <p className="error-message">{error}</p>}
                                {success && <p className="success-message">Correo de restablecimiento enviado.</p>}
                            </form>
                        </div>
                        <p>¡Recupera y <strong>continua recibiendo beneficios!</strong></p>
                    </div>
                </>
            )}
        </div>
    );
};

export default ForgotPassword;
