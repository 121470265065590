import './BasicTemplate.scss'
import { Outlet } from 'react-router-dom';

const BasicTemplate = () => {
    return(
        <div className="basic-template-container">
            <Outlet /> {/* Esto es donde Welcome será renderizado */}
        </div>
    )
}

export default BasicTemplate;