// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBABrAAgR72vXA_4HxC8v5Cu-RW_yZ7_gY",
  authDomain: "noborus-app-2.firebaseapp.com",
  projectId: "noborus-app-2",
  storageBucket: "noborus-app-2.firebasestorage.app",
  messagingSenderId: "1017872971428",
  appId: "1:1017872971428:web:70ff4e380001f98cce5831",
  measurementId: "G-L2J1L853Z0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const imgageDB = getStorage (app)