import './MenuBar.scss';
import Menu from '../Icons/MenuIcon';
import homeIcon from '../../../assets/icons/house.svg';
import storeIcon from '../../../assets/icons/shop-window.svg'
import personIcon from '../../../assets/icons/person.svg'
import logout from '../../../assets/icons/box-arrow-right.svg'
import { useNavigate } from 'react-router-dom';

const MenuBar = () => {
    const navigate = useNavigate();
    const logoutRedirect = () => {
        localStorage.clear()
        window.location.reload()
    }

    const icons = [
        { icon: homeIcon, label: 'Home', id: 'home',  action: () => navigate('/home') },
        { icon: storeIcon, label: 'Store', id: 'store', action: () => navigate('/commerce') },
        { icon: personIcon, label: 'Person', id: 'person', action: () => navigate('/profile') },

    ];

    const icons2 = [
        { icon: logout, label: 'Home', id: 'home', action:logoutRedirect},
    ];



    return (
        <div className="menu-template-bar-container">
            <div className="menu-template-bar-container-item">
                <div className="menu-template-bar-container-item-left">
                    <Menu icons={icons}></Menu>
                </div>
                <div className="menu-template-bar-container-item-center">
                </div>
                <div className="menu-template-bar-container-item-rigth">
                    <Menu icons={icons2}></Menu>
                </div>
            </div>
        </div>
    );
}

export default MenuBar;
