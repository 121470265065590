import './MenuBar.scss';
import Menu from '../Icons/MenuIcon';
import homeIcon from '../../../assets/icons/house.svg';
import storeIcon from '../../../assets/icons/shop-window.svg';
import personIcon from '../../../assets/icons/person.svg';

// import logout from '../../../assets/icons/box-arrow-right.svg';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { fetchData } from '../../../requests/requests';
import { useSelector } from 'react-redux';
import environment from '../../../enviroment/enviroment';

const MenuBar = ({ setSearchData, setCommerceData }) => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const user0 = JSON.parse(localStorage.getItem('user'));
    const user = useSelector(state => state.usuario);

    useEffect(() => {
        const fetchCommerceData = async () => {
            if (search.trim() !== '') {
                try {
                    const response = await fetchData(
                        environment.api.private_api_url,
                        '/commerce/list_comerce',
                        {},
                        { commerce_key: search.toLowerCase() }
                    );
                    setCommerceData(response.data); // Actualiza los datos de comercio
                } catch (error) {
                    setCommerceData([]);
                    console.error('Error fetching commerce data:', error);
                }
            } else {
                setCommerceData([]); // Limpia los datos si no hay búsqueda
            }
        };

        fetchCommerceData();
    }, [search, setCommerceData]); // Agregamos setCommerceData a las dependencias

    const logoutRedirect = () => {
        localStorage.clear();
        window.location.reload();
    };

    const handleLoging = () => {
        if (user0) {
            logoutRedirect();
        } else {
            navigate('/');
        }
    };

    const icons = [
        { icon: homeIcon, label: 'Home', id: 'home', action: () => navigate('/home') },
        ...(user?.user.company ? [{ icon: storeIcon, label: 'Store', id: 'store', action: () => navigate('/commerce') }] : []),
        ...(user?.user ? [{ icon: personIcon, label: 'person', id: 'person', action: () => navigate('/profile') }] : []),
    ];

    const handleInputChange = (event) => {
        const value = event.target.value.trim();
        setSearch(value); // Actualiza el estado local de `search`
        setSearchData(value); // Actualiza el estado de `searchData` en HomeTemplate
    };

    return (
        <div className="menu-home-template-bar-container">
            <div className="menu-home-template-bar-container-item">
                <div className='menu-home-template-bar-container-item-left'>
                    <Menu icons={icons} />
                </div>
                <div className="menu-home-template-bar-container-item-center">
                    <div className="menu-home-template-bar-container-item-center-search">
                        <input
                            type="text"
                            value={search}
                            onChange={handleInputChange}
                            placeholder="Search..."
                        />
                    </div>
                </div>
                <div className="menu-home-template-bar-container-item-right">
                    <div className="menu-home-template-bar-container-item-right">
                        <p onClick={handleLoging}>{user0 ? 'Logout' : 'Login'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuBar;
