import React, { useState, useEffect, useCallback } from 'react';
import addIcon from '../../../assets/icons/plus-lg.svg'
import checkIcon from '../../../assets/icons/check-lg.svg';
import Loading from '../../utils/loading/Loading';

import wappIcon from '../../../assets/icons/whatsapp.svg';
import instaIcon from '../../../assets/icons/instagram.svg';
import LinkIcon from '../../../assets/icons/linkedin.svg';
import { fetchData, postData } from '../../../requests/requests';
import environment from '../../../enviroment/enviroment';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './CommercePlace.scss';

const CommercePlace = () => {
    const [loading, setLoading] = useState(false);
    const [commerceData, setCommerceData] = useState();
    const [userCommerce, setUserCommerce] = useState();
    const user = useSelector(state => state.usuario);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const commerce_id = queryParams.get('commerce_id');

    const icons = [
        commerceData?.whatsapp ? { icon: wappIcon, label: 'WhatsApp', id: 'wpp', url: commerceData?.whatsapp } : null,
        commerceData?.instagram ? { icon: instaIcon, label: 'Instagram', id: 'insta', url: commerceData?.instagram } : null,
        commerceData?.linkedin ? { icon: LinkIcon, label: 'LinkedIn', id: 'lkin', url: commerceData?.linkedin } : null,
    ].filter(Boolean);

    const joinToCommerce = async () => {
        setLoading(true);
        const params = {
            commerce_nickname: commerceData.nickname,
        };

        try {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            const response = await postData(
                environment.api.private_api_url,
                '/follow/create_follow',
                {},
                token,
                params
            );
            console.log(response);
            fetchCommerceData(); // Llama a fetchCommerceData para actualizar los datos
        } catch (error) {
            console.error('Error fetching token or data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCommerceData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetchData(
                environment.api.private_api_url,
                '/commerce/list_comerce',
                {},
                { commerce_key: commerce_id, customer: user?.user.id }
            );
            console.log({ commerce_key: commerce_id, customer: user?.user.id });
            setCommerceData(response.data[0].c);
            setUserCommerce(response.data[0].customer);
        } catch (error) {
            console.error('Error fetching commerce data:', error);
        } finally {
            setLoading(false);
        }
    }, [commerce_id, user?.user.id]);

    useEffect(() => {
        fetchCommerceData();
    }, [fetchCommerceData]);

    const handleFollow = () => {
        if (!user) {
            alert('Debes estar registrado para ver esta sección');
        } else {
            if (!userCommerce) {
                joinToCommerce();
            }
        }
    };

    return (
        <div className="commerce-place-container">
            {loading && <Loading />}
            {!loading && (
                <div className="commerce-place-container-blocks">
                    <div className="commerce-place-container-blocks-profile">
                        <div className="commerce-place-container-blocks-profile-portada">
                            <img src={commerceData?.img_url || 'https://www.freepik.com/photos/profile'} alt="Portada del comercio" />
                        </div>
                        <div className="commerce-place-container-blocks-profile-info">
                            <div className="commerce-place-container-blocks-profile-info-name-follow">
                                <h4>{commerceData?.name}</h4>

                                <div className="commerce-place-container-blocks-profile-info-name-follow-container">
                                    <p>{userCommerce ? 'Siguiendo' : 'Seguir'}</p>
                                    <div
                                        className={
                                            userCommerce
                                                ? "commerce-place-container-blocks-profile-info-name-follow-button-blocked"
                                                : "commerce-place-container-blocks-profile-info-name-follow-button"
                                        }
                                        onClick={() => handleFollow()}
                                    >
                                        <ReactSVG src={userCommerce ? checkIcon : addIcon} className="commerce-place-container-blocks-profile-info-name-follow-button-icon" />
                                    </div>
                                </div>

                            </div>
                            <div className="commerce-place-container-blocks-profile-info-social">
                                {icons.map((icon) => (
                                    <a href={icon.url} target="_blank" rel="noopener noreferrer" key={icon.id}>
                                        <img src={icon.icon} alt={icon.label} className="commerce-place-container-blocks-profile-info-social-icon" />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CommercePlace;