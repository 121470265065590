import React from 'react'; // Asegúrate de que está importado
import './IconSingle.scss';

const IconSingle = ({ icon, label, isActive, onClick }) => {
    return (
        <div className={`menu-template-icon-container ${isActive ? 'active' : ''}`} onClick={onClick}>
            <img src={icon} alt={label} />
            {isActive && <div className="active-line"></div>} {/* Línea de activación */}
        </div>
    );
}

export default IconSingle;
