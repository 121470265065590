import React, { useState } from 'react';
import './Benefits.scss'; // Ajustar la ruta según sea necesario
import leftArroIcon from '../../../assets/icons/arrow-left-square.svg';
import { useNavigate } from 'react-router-dom';
import Cashback from './Cashback/Cashback';

const Benefits = () => {

    const navigate = useNavigate()
    const [view, setView] = useState('Cashback')

    const handleRedirectBack = () => {
        navigate('/commerce')
    }


    return ( 
        <>
                <div className="benefits-container">
                    <div className="benefits-canvas">
                        <div className="benefits-title">
                            <div className='benefits-title-text'>
                                <img src={leftArroIcon} alt="back" onClick={() => handleRedirectBack()} />
                                <h2>{view}</h2>
                            </div>
                            <div className='benefits-title-selector'>
                                <select
                                        className="m-0"
                                        id="itemsPerPage"
                                        value={view}
                                        onChange={setView}
                                    >
                                        <option value="cashback">Cashback</option>
                                </select>
                            </div>

                        </div>
                        <div className="benefits-table">
                            <Cashback></Cashback>
                        </div>
                    </div>
                </div>
        </>
    );
};

export default Benefits;
