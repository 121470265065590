import React, { useState } from 'react';
import { postData } from '../../../requests/requests';
import './CreateAccount.scss';
import environment from '../../../enviroment/enviroment';
import Loading from '../../utils/loading/Loading';
import { v4 as uuidv4 } from 'uuid';
import Success from '../../components/success/Success';
import Error from'../../components/error/Error';
import { useNavigate } from 'react-router-dom';

const CreateAccount = () => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        documentNumber: '',
        phoneNumber: '',
        birthDate: '',
        documentType: 'cc' // Valor por defecto para el tipo de documento
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isbuttonBlocked, setisbuttonBlocked] = useState(false);

    const [page, setPage] = useState(localStorage.getItem('user')?2:1);
    const [showPassword, setShowPassword] = useState(false);

    const logoutRedirect = () => {
        if (page > 1 ){
            localStorage.clear()
            window.location.reload()
        } else{
            navigate('/')
        }

    }
    const getToken = async () => {
        setLoading(true)
        const url = environment.auth0.url_api;
        const data = {
            grant_type: 'password',
            username:formData.email,
            password:formData.password,
            audience: environment.auth0.audience,
            scope: 'openid profile email',
            client_id: environment.auth0.client_id,
            client_secret: environment.auth0.client_secret,
        };
        try {
            const response = await postData(url, '/oauth/token', data);
            if (response?.access_token) {
                // Assuming setToken is defined elsewhere
                localStorage.setItem('user', JSON.stringify(response));
                localStorage.setItem('user_email', formData.email)
                localStorage.setItem('sesionUntill', (new Date().getTime() + response.expires_in).toString())
                window.location.reload();
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };

    const createUSerDb = async (body) => {
        setLoading(true)
        try {
            let token = JSON.parse(localStorage.getItem('user')).access_token;
            await postData(
                environment.api.private_api_url,
                '/user/create_user',
                body,
                token
            );
            console.log("Usuario creado exitosamente");
            setSuccess(true);
            setLoading(false);
        } catch (error) {
            console.error("Error al crear usuario en la base de datos:", error);
            setError(true);
            setLoading(false); // Asegura que setLoading se ponga en false en caso de error
        }
    };
    
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    const handleDocumentTypeChange = (e) => {
        const { value } = e.target;
        setFormData((prev) => ({ ...prev, documentType: value }));
    };

    const validatePassword = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        return password.length >= 6 && hasUpperCase && hasSpecialChar;
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateCurrentPage = () => {
        if (page === 1) {
            if (!formData.email || !formData.password) {
                setError(true);
                return false;
            }
            if (!validateEmail(formData.email)) {
                setError(true);
                return false;
            }
            if (!validatePassword(formData.password)) {
                setError(true);
                return false;
            }
        } else if (page === 2) {
            if (!formData.firstName || !formData.lastName) {
                setError(true);
                return false;
            }
        } else if (page === 3) {
            if (!formData.documentNumber) {
                setError(true);
                return false;
            }
        } else if (page === 4) {
            if (!formData.phoneNumber) {
                setError(true);
                return false;
            }
        } else if (page === 5) {
            if (!formData.birthDate) {
                setError(true);
                return false;
            }
        }
        return true;
    };

    const createUser = async () => {
        setLoading(true);
        const url = environment.auth0.url_api;
        const data = {
            "client_id": environment.auth0.client_id,
            "email": formData.email,
            "password": formData.password,
            "connection": "Username-Password-Authentication"
        };
        try {
            await postData(url, '/dbconnections/signup', data);
                await getToken()
                setPage(2)
        } catch (error) {
            setError(true);
            setLoading(false)
        }
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        setisbuttonBlocked(true)
        if (page === 1) {
            await createUser();
            setisbuttonBlocked(false)
        } else if (validateCurrentPage()) {
            if (page < 5) {
                setPage(page + 1);
                setisbuttonBlocked(false)
            } else {
                const newUser = {
                    nickname: uuidv4(),
                    name: formData.firstName,
                    last_name: formData.lastName,
                    email: localStorage.getItem('user_email'),
                    document_type: formData.documentType,
                    document: formData.documentNumber,
                    phone: formData.phoneNumber,
                    birth_date: new Date(formData.birthDate).getTime().toString(),
                    city_id: '01e2e8e1-e829-442b-aa77-da0dc416b612',
                    address: 'na'
                };
                await createUSerDb(newUser);
                setisbuttonBlocked(false);
            }
            
        }
    };

    const handlePreviousPage = () => {
        if (page > 2) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < 5) {
            setPage(page + 1);
        }
    };

    return (
        <div className="create-account-container">
            {loading && <Loading />}
            {success&&<Success message='El usuario ha sido creado exitosamente' onAction={()=> window.location.reload()}></Success>}
            {error&&<Error message='Ha pocurrido un error interno, intentelo mas tarde'></Error>}
            {!loading && (
                <>
                    <div className="create-account-container-buttons">
                        <h1>FIDE<span>YA</span></h1>
                        <div className="create-account-container-buttons-cta">
                            <form className="create-account-container-buttons-cta-form" onSubmit={handleSubmit}>
                            <p className='instruction-head'>Llena los campos en blanco para crear tu usuario</p>

                                {page === 1 && (
                                    <>
                                        <div className="create-account-container-buttons-cta-form-input">
                                            <p>Correo</p>
                                            <input
                                                type="email"
                                                id="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                placeholder='Correo'
                                                required 
                                                aria-label="Correo electrónico" />
                                        </div>
                                        <div className="password">
                                            <div className="create-account-container-buttons-cta-form-input">
                                            <p>Contraseña</p>
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    id="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    placeholder='Contraseña'
                                                    required 
                                                    aria-label="Contraseña" />
                                            </div>
                                            <button
                                                type="button"
                                                className="toggle-password-button"
                                                onClick={() => setShowPassword(!showPassword)}
                                                aria-label={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                                            >
                                                {showPassword ? 'Ocultar' : 'Mostrar'}
                                            </button>
                                        </div>
                                        <p className='instruction'>La contraseña debe tener una logitud minima de 6 caracteres, una letra mayuscula y un caracter especial</p>
                                    </>
                                )}

                                {page === 2 && (
                                    <>
                                        <div className="create-account-container-buttons-cta-form-input">
                                        <p>Nombre</p>
                                            <input
                                                type="text"
                                                id="firstName"
                                                 value={formData.firstName}
                                                onChange={handleChange}
                                                placeholder='Nombre'
                                                required 
                                                aria-label="Nombre" />
                                        </div>
                                        <div className="create-account-container-buttons-cta-form-input">
                                        <p>Apellido</p>

                                            <input
                                                type="text"
                                                id="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                placeholder='Apellido'
                                                required 
                                                aria-label="Apellido" />
                                        </div>
                                    </>
                                )}

                                {page === 3 && (
                                    <>
                                        <select
                                            className=""
                                            id="documentType"
                                            value={formData.documentType}
                                            onChange={handleDocumentTypeChange}
                                            aria-label="Tipo de documento"
                                        >
                                            <option value="cc">Cédula</option>
                                            <option value="ti">Tarjeta identidad</option>
                                            {/* Aquí puedes agregar más tipos de documentos si lo necesitas */}
                                        </select>
                                        <div className="create-account-container-buttons-cta-form-input">
                                            <input
                                                type="text"
                                                id="documentNumber"
                                                value={formData.documentNumber}
                                                onChange={handleChange}
                                                placeholder='Número de documento'
                                                required
                                                aria-label="Número de documento" />
                                        </div>
                                    </>
                                )}

                                {page === 4 && (
                                    <div className="create-account-container-buttons-cta-form-input">
                                        <p>Número de teléfono</p>
                                        <input
                                            type="text"
                                            id="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            placeholder='Número de teléfono'
                                            required 
                                            aria-label="Número de teléfono" />
                                    </div>
                                )}

                                {page === 5 && (
                                    <div className="create-account-container-buttons-cta-form-input">
                                        <p>Fecha nacimiento</p>
                                        <input
                                            type="date"
                                            id="birthDate"
                                            value={formData.birthDate}
                                            onChange={handleChange}
                                            placeholder='Fecha de nacimiento'
                                            required 
                                            aria-label="Fecha de nacimiento" />
                                    </div>
                                )}
                                <button
                                    type="submit"
                                    className="create-account-container-buttons-cta-form-button"
                                    disabled={isbuttonBlocked}

                                >
                                    {page === 1 ? 'Crear usuario' : page === 5 ? 'Enviar' : 'Guardar'}
                                </button>

                                {page > 1 && (
                                    <div className="content-template-canvas-pagination-buttons">
                                        <button
                                            type="button"
                                            onClick={handlePreviousPage}
                                            disabled={page === 1 || loading}
                                            className="page-button"
                                            aria-label="Página Anterior"
                                        >
                                            {'<'}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleNextPage}
                                            disabled={page === 5 || loading}
                                            className="page-button"
                                            aria-label="Página Siguiente"
                                        >
                                            {'>'}
                                        </button>
                                    </div>
                                )}
                            </form>
                        </div>
                        { page > 1 && <p onClick={()=> logoutRedirect()} className='handle-back' >Logout</p>}
                        { page === 1 && <p onClick={()=> logoutRedirect()} className='handle-back'>Salir</p>}
                    </div>
                </>
            )}
        </div>
    );
};

export default CreateAccount;
