import './MenuTemplate.scss'
import { Outlet } from 'react-router-dom';

import MenuBar from './MenuBar/MenuBar';
import LateralManu from './LateralMenu/LateralMenu';

const MenuTemplate = () => {
    return(
        <div className="menu-template-container">
            <MenuBar></MenuBar>

            <div className="menu-template-container-lateral-menu" style={{display:'block'}}>
                <LateralManu></LateralManu>
            </div>
            <div className="menu-template-container-content">
                    <Outlet />
            </div>
        </div>
    )
}

export default MenuTemplate;