import './LateralMenu.scss';
import exampleIcon from '../../../assets/icons/house.svg';
import { useNavigate } from 'react-router-dom';

const LateralMenu = ({ icons = [{ name: 'icon', url_img: exampleIcon, url_redirect: '/' }]}) => {
    const navigate = useNavigate();

    const handleRedirect = (url) => {
        if (url) {
            navigate(url);
        }
    };

    return (
        <div className="lateral-menu-container">
            <div className="lateral-menu-container-items">
                {icons.map((icon, index) => (
                    <div 
                        key={index} 
                        className="lateral-menu-container-items-item" 
                        onClick={() => handleRedirect(icon.url_redirect)}
                    >
                        <img src={icon.url_img} alt={icon.name} className="icon-image" />
                        <span className="icon-name">{icon.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LateralMenu;
