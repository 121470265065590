import './SearchCommece.scss';
import { useNavigate } from 'react-router-dom';

const SearchCommece = ({ data = [], setSearchData }) => {
    const navigate = useNavigate();

    const redirectCommerce = (commerce_id) => {
        setSearchData(''); // Limpia los datos de búsqueda
        navigate(`/home/commerce_place?commerce_id=${commerce_id}`);
    };

    return (
        <div className="search-commerce-template-container">
            <div className="search-commerce-template-container-content">
                {Array.isArray(data) && data.length > 0 ? (
                    data.map((item, index) => (
                        <div 
                            key={item.c?.id || index} 
                            className="search-commerce-template-container-content-item" 
                            onClick={() => redirectCommerce(item.c.id)}
                        >
                            <div className="search-commerce-template-container-content-item-img-container">
                                <div className="search-commerce-template-container-content-item-img-container-img">
                                    <img
                                        src={item.c?.img_url || "data:image/png;base64,..."} // Imagen de respaldo
                                        alt={item.c?.name || "Commerce Item"}
                                    />
                                </div>
                            </div>
                            <div className="search-commerce-template-container-content-item-text-container">
                                <p><strong>{item.c?.name || "Noborus"}</strong></p>
                                <p>{item.c?.type || "Commerce"}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No hay datos disponibles</p>
                )}
            </div>
        </div>
    );
};

export default SearchCommece;
