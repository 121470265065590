// reducers/set_usuarios.js
import { SET_USUARIO } from "../actions/get_usuario/tipo";

const initialState = {
  usuario: null,
};

export const setUsuarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USUARIO:
      return { ...state, usuario: action.payload };
    default:
      return state;
  }
};
