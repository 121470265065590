
const auth0 = {
    domain : 'dev-bcmraukanc6g4p3k.us.auth0.com',
    audience: 'https://dev-bcmraukanc6g4p3k.us.auth0.com/api/v2/',
    url_api: 'https://dev-bcmraukanc6g4p3k.us.auth0.com',
    client_id:'7vTnL7SJC4IvhmiEtbW2sTDCODTWq4Fy',
    client_secret:'CzOj2NrmP_NJQnnWWEAxv_ZYZLRDiBNFTY-J6sULt_BP0POT8IUks2w8LzLx5Ltl',
  };
  
  const api = {
    private_api_url: 'https://noborus-private-anhjexf4azebhcag.westus-01.azurewebsites.net',
    open_api_url: 'https://noborus-open-eqakcgfnaahaepdh.westus-01.azurewebsites.net'
  };
    
  const environment = {
    auth0: auth0,
    api:api
  };
  
  export default environment;
    