import './HomeTemplate.scss';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';

import MenuBar from './MenuBar/MenuBar'; // Asegúrate de que la ruta y el nombre sean correctos.
import SearchCommece from './SearchCommece/SearchCommece'; // Verifica la ruta y la exportación del componente.

const HomeTemplate = () => {
    const [searchData, setSearchData] = useState('');
    const [commerceData, setCommerceData] = useState([]);

    return (
        <div className="home-template-container">
            <MenuBar setSearchData={setSearchData} setCommerceData={setCommerceData} />
            {searchData && <SearchCommece data={commerceData} setSearchData={setSearchData} />} {/* Asegúrate de que el componente sea correcto */}
            <div className="home-template-container-content">
                <Outlet />
            </div>
        </div>
    );
};

export default HomeTemplate;
