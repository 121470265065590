// services/apiService.js
import axios from 'axios';

export const fetchData = async (url, endpoint, pathParams = {}, queryParams = '', token = '') => {
  try {
    // Reemplaza los parámetros dinámicos de la ruta, como {param}
    let finalEndpoint = endpoint;

    // Si hay parámetros en la ruta, reemplázalos
    if (pathParams && typeof pathParams === 'object') {
      Object.keys(pathParams).forEach((key) => {
        finalEndpoint = finalEndpoint.replace(`{${key}}`, pathParams[key]);
      });
    }

    // Realiza la solicitud GET con los parámetros de consulta y el token
    const response = await axios.get(`${url}${finalEndpoint}`, {
      params: queryParams,  // Parámetros de consulta
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Extrae el mensaje de error del objeto de error
    const errorMessage = error.response?.data?.detail || error.message || 'Error en la solicitud';
    throw new Error(errorMessage);
  }
};

// Función para hacer una solicitud POST con un cuerpo, endpoint, Bearer Token y parámetros de consulta
export const postData = async (url, endpoint, data, token = '', queryParams = {}) => {
  try {
    const response = await axios.post(`${url}${endpoint}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: queryParams // Agrega los parámetros de consulta aquí
    });
    return response.data;
  } catch (error) {
    // Extrae el mensaje de error del objeto de error
    const errorMessage = error.response?.data?.detail || error.message || 'Error en la solicitud';
    console.log(error);
    throw new Error(errorMessage);
  }
};

// Función para hacer una solicitud POST con un cuerpo, endpoint, Bearer Token y parámetros de consulta
export const putData = async (url, endpoint, data, token = '', queryParams = {}) => {
  try {
    const response = await axios.put(`${url}${endpoint}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: queryParams // Agrega los parámetros de consulta aquí
    });
    return response.data;
  } catch (error) {
    // Extrae el mensaje de error del objeto de error
    const errorMessage = error.response?.data?.detail || error.message || 'Error en la solicitud';
    console.log(error);
    throw new Error(errorMessage);
  }
};


