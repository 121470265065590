import React, { useEffect, useState } from 'react';
import './CreateComerce.scss'; // Ajusta la ruta según sea necesario
import environment from '../../../enviroment/enviroment';
import { postData } from '../../../requests/requests';
import { v4 as uuidv4, v4 } from 'uuid';
import Loading from '../../utils/loading/Loading';
import { imgageDB } from '../../../firebase/firebaseCongif';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const CreateComerce = () => {
    const [img, setImg] = useState();
    const [formData, setFormData] = useState({
        email: '',
        email_billing: '',
        name: '',
        documentNumber: '',
        phoneNumber: '',
        documentType: 'cc',
        img_url: '',
        whatsapp:'',
        instagram:'',
        linkedin:''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const handleClickImg = async () => {
            try {
                const imgRef = ref(imgageDB, `commerceImg/${v4()}`);
                const uploadReference = await uploadBytes(imgRef, img); // Await aquí
                const downloadURL = await getDownloadURL(uploadReference.ref); // Usar el await del reference
                setFormData((prev) => ({ ...prev, img_url: downloadURL })); // Actualizar formData con el URL de descarga
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        };

        if (img) {
            handleClickImg();
        }
    }, [img]);

    const createCommerce = async (body) => {

        setLoading(true)
        console.log(body);

        try {
            let token = JSON.parse(localStorage.getItem('user')).access_token;
            const response = await postData(
                environment.api.private_api_url,
                '/commerce/create_commerce',
                body,
                token
            );
            console.log(response);
            setSuccess('Usuario creado con éxito.');
            setError(null);
            window.location.reload();
        } catch (error) {
            console.error('Error creating user:', error);
            const errorMessage = error.message || 'Error al crear el usuario';
            setError(errorMessage);
            setSuccess(null);
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    const handleDocumentTypeChange = (e) => {
        const { value } = e.target;
        setFormData((prev) => ({ ...prev, documentType: value }));
    };

    // const validatePassword = (password) => {
    //     const hasUpperCase = /[A-Z]/.test(password);
    //     const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    //     return password.length >= 7 && hasUpperCase && hasSpecialChar;
    // };

    // const validateEmail = (email) => {
    //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     return emailRegex.test(email);
    // };

    const validateCurrentPage = () => {
        if (page === 1) {
            if (!formData.name) {
                setError("Por favor, completa todos los campos.");
                return false;
            }
        } else if (page === 2) {
            if (!formData.documentType || !formData.documentNumber) {
                setError("Por favor, completa todos los campos.");
                return false;
            }
        } else if (page === 3) {
            if (!formData.phoneNumber) {
                setError("Por favor, ingresa el número de documento.");
                return false;
            }
        } else if (page === 4) {
            if (!formData.email || ! formData.email_billing) {
                setError("Por favor, ingresa el número de teléfono.");
                return false;
            }
        } else if (page === 5) {
            if (!formData.email || ! formData.email_billing) {
                setError("Por favor, ingresa el número de teléfono.");
                return false;
            }
        }
        setError('');
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateCurrentPage()) {
            if (page < 6) {
                setPage(page + 1);
            } else {
                const newUser = {
                    nickname: uuidv4(),
                    name: formData.name,
                    document_type: formData.documentType,
                    document: formData.documentNumber,
                    address: '',
                    city_id: '01e2e8e1-e829-442b-aa77-da0dc416b612',
                    phone: formData.phoneNumber,
                    email: formData.email_billing,
                    email_contact: formData.email,
                    img_url: formData.img_url,
                    role: 'admin',
                    whatsapp:formData.whatsapp,
                    instagram:formData.instagram,
                    linkedin:formData.linkedin
                };
                console.log(newUser);
                createCommerce(newUser);
            }
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < 6) {
            setPage(page + 1);
        }
    };

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <div className="create-commerce-container">
                    <div className="create-commerce-canvas">
                        <div className="create-commerce-canvas-container">
                            <div className="create-commerce-container-buttons">
                                <h3>Crea tu empresa ahora</h3>
                                <p>¡Incentiva a tu clientes y haz crecer tu negocio!</p>
                                <div className="create-commerce-container-buttons-cta">
                                    <form className="create-commerce-container-buttons-cta-form" onSubmit={handleSubmit}>
                                        <p>Llena los campos en blanco para crear tu usuario</p>

                                        {page === 1 && (
                                            <div className="create-commerce-container-buttons-cta-form-input">
                                                
                                                <input
                                                    type="text"
                                                    id="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder='Nombre'
                                                    required
                                                    aria-label="Nombre"
                                                />
                                            </div>
                                        )}

                                        {page === 2 && (
                                            <>
                                                <select
                                                    id="documentType"
                                                    value={formData.documentType}
                                                    onChange={handleDocumentTypeChange}
                                                    aria-label="Tipo de documento"
                                                >
                                                    <option value="cc">Cédula</option>
                                                    <option value="ti">Tarjeta identidad</option>
                                                    <option value="nit">NIT</option>
                                                </select>
                                                <div className="create-commerce-container-buttons-cta-form-input">
                                                    <input
                                                        type="text"
                                                        id="documentNumber"
                                                        value={formData.documentNumber}
                                                        onChange={handleChange}
                                                        placeholder='Número de documento'
                                                        required
                                                        aria-label="Número de documento"
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {page === 3 && (
                                            <div className="create-commerce-container-buttons-cta-form-input">
                                                <input
                                                    type="text"
                                                    id="phoneNumber"
                                                    value={formData.phoneNumber}
                                                    onChange={handleChange}
                                                    placeholder='Número de teléfono'
                                                    required
                                                    aria-label="Número de teléfono"
                                                />
                                            </div>
                                        )}

                                        {page === 4 && (
                                            <>
                                                <div className="create-commerce-container-buttons-cta-form-input">
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        placeholder='Correo'
                                                        required
                                                        aria-label="Correo"
                                                    />
                                                </div>
                                                <div className="create-commerce-container-buttons-cta-form-input">
                                                    <input
                                                        type="email"
                                                        id="email_billing"
                                                        value={formData.email_billing}
                                                        onChange={handleChange}
                                                        placeholder='Correo facturación'
                                                        required
                                                        aria-label="Correo de facturación"
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {page === 5 && (
                                            <>
                                                <div className="create-commerce-container-buttons-cta-form-input">
                                                    <input
                                                        type="text"
                                                        id="whatsapp"
                                                        value={formData.whatsapp}
                                                        onChange={handleChange}
                                                        placeholder='Whatsapp'
                                                        aria-label="Correo"
                                                    />
                                                </div>
                                                <div className="create-commerce-container-buttons-cta-form-input">
                                                    <input
                                                        type="text"
                                                        id="linkedin"
                                                        value={formData.linkedin}
                                                        onChange={handleChange}
                                                        placeholder='LinkedIn'
                                                        aria-label="Correo de facturación"
                                                    />
                                                </div>
                                                <div className="create-commerce-container-buttons-cta-form-input">
                                                    <input
                                                        type="text"
                                                        id="instagram"
                                                        value={formData.instagram}
                                                        onChange={handleChange}
                                                        placeholder='Instagram'
                                                        aria-label="Correo de facturación"
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {page === 6 && (
                                            <div className="custom-file-input-wrapper">
                                                <input 
                                                    type="file" 
                                                    id="img_url" 
                                                    onChange={(e) => setImg(e.target.files[0])} 
                                                    className="custom-file-input" 
                                                    required 
                                                    aria-label="Imagen comercio"
                                                />
                                                <label htmlFor="img" className="custom-file-label">Subir Imagen</label>
                                            </div>
                                        )}

                                        <button
                                            type="submit"
                                            className="create-commerce-container-buttons-cta-form-button"
                                            disabled={loading}
                                        >
                                            {page === 6 ? 'Enviar' : 'Guardar'}
                                        </button>

                                        {page > 0 && (
                                            <div className="create-commerce-template-canvas-pagination-buttons">
                                                <button
                                                    type="button"
                                                    onClick={handlePreviousPage}
                                                    disabled={page === 1 || loading}
                                                    className="page-button"
                                                    aria-label="Página Anterior"
                                                >
                                                    {'<'}
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={handleNextPage}
                                                    disabled={page === 7 || loading}
                                                    className="page-button"
                                                    aria-label="Página Siguiente"
                                                >
                                                    {'>'}
                                                </button>
                                            </div>
                                        )}
                                    </form>

                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                    {success && <p style={{ color: 'green' }}>{success}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateComerce;
