import React, { useEffect, useState } from 'react';
import './Cashback.scss';
import environment from '../../../../enviroment/enviroment';
import Loading from '../../../utils/loading/Loading';
import { postData } from '../../../../requests/requests';
import { useNavigate } from 'react-router-dom';
import Success from '../../../components/success/Success';
import Error from '../../../components/error/Error';
 
const Cashback = () => {
    const navigate = useNavigate();
    const usuario = JSON.parse(localStorage.getItem('customer'));

    // State management
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [redimir, setRedimir] = useState(null);
    const [valor, setValor] = useState('');
    const [porcentaje, setPorcentaje] = useState('');
    const [fecha, setFecha] = useState('');
    const [page, setPage] = useState(1);

    // Formats a number as currency
    const formatNumber = (number) => {
        return new Intl.NumberFormat('es-ES', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(number);
    };

    // Functions to create and redeem cashback
    const handleCashbackRequest = async (apiEndpoint, cashbackData) => {
        setLoading(true);
        try {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            await postData(environment.api.open_api_url, apiEndpoint, cashbackData, token);
            setSuccess(true);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        if (page === 1) {
            const valorNum = parseFloat(valor);
            if (isNaN(valorNum) || valorNum <= 0) return;
        
            const porcentajeNum = parseFloat(porcentaje);
            // Verifica que el porcentaje sea positivo y menor o igual a 100
            if (!redimir && (isNaN(porcentajeNum) || porcentajeNum < 1 || porcentajeNum > 100)) {
                alert("El porcentaje debe estar entre 1 y 100."); // Mensaje de error
                return;
            }
        
            setPage(2);  // Cambia a la siguiente página
        }
        
        if (page === 2) {
            const valorNum = parseFloat(valor);
            const porcentajeNum = parseFloat(porcentaje);
    
            // Validar la fecha solo si no estamos en el proceso de redimir
            if (!redimir) {
                // Verificar si la fecha está correctamente definida
                if (!fecha || isNaN(new Date(fecha).getTime())) {
                    alert("Por favor, selecciona una fecha válida.");
                    return;
                }
    
                // Convertir la fecha a UTC antes de enviarla al backend
                new Date(fecha).toISOString();  // Convertimos la fecha a formato UTC
            }
    
            // Preparar los datos para la solicitud
            const cashbackData = {
                id_user: usuario?.id,
                amount: valorNum * (porcentajeNum / 100),
                expirable: true,
                expire_date: !redimir ? new Date(fecha).toISOString() : null,  // Solo asignamos la fecha si no estamos redimiendo
            };
    
            const cashbackDataRedeem = {
                id_user: usuario?.id,
                amount: valorNum,
            };
    
            // Enviar la solicitud correspondiente
            redimir 
                ? await handleCashbackRequest('/cashback/redeem_cashback', cashbackDataRedeem)
                : await handleCashbackRequest('/cashback/create_cashback', cashbackData);
        }
    };
    

    // Handlers for controlled inputs
    const handleValorChange = (e) => setValor(e.target.value);
    const handlePorcentajeChange = (e) => setPorcentaje(e.target.value);

    useEffect(()=>{
        if (porcentaje > 100){
            setPorcentaje(100)
        } else if (porcentaje < 0) {
            setPorcentaje(0)
        }
    },[porcentaje])

    // Get current date and 2 months later date
    const currentDate = new Date();
    const twoMonthsLater = new Date(currentDate);
    twoMonthsLater.setMonth(currentDate.getMonth() + 2);

    // Format dates as yyyy-mm-dd (to show in the input as min/max)
    const minDate = currentDate.toISOString().split('T')[0];  // Current date (min)
    const maxDate = twoMonthsLater.toISOString().split('T')[0];  // Date after 2 months (max)

    return (
        <>
            {loading && <Loading />}
            {error && <Error message="Ha ocurrido un error, inténtelo de nuevo" />}
            {success && <Success message={"Operación exitosa, " + usuario.name + ' quedó con un saldo de $ ' + redimir?(formatNumber(parseFloat(usuario.cashback_balance) - parseFloat(valor))):(formatNumber(parseFloat(usuario.cashback_balance) + parseFloat(valor))) } onAction={() => navigate('/commerce')} />}
            {!loading && (
                <div className="cashback-container">
                    <div className="cashback-container-info">
                        <div className="cashback-container-info-item">
                            <p className="cashback-container-info-item-name">{usuario.name + ' ' + usuario.last_name}</p>
                        </div>
                        <div className="cashback-container-info-item">
                            <p className="cashback-container-info-item-value">$ {formatNumber(usuario.cashback_balance)}</p>
                        </div>
                    </div>
                    <div className="cashback-container-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                            {redimir === null && page === 1 && (
                                <>
                                    <div className="cashback-buttons-cta-form-input-container">
                                        <button
                                            type="button"
                                            className="cashback-buttons-cta-form-button"
                                            onClick={() => setRedimir(false)}
                                        >
                                            Cargar cashback
                                        </button>
                                    </div>
                                    <div className="cashback-buttons-cta-form-input-container">
                                        <button
                                            type="button"
                                            className="cashback-buttons-cta-form-anti-button"
                                            onClick={() => setRedimir(true)}
                                        >
                                            Redimir cashback
                                        </button>
                                    </div>
                                </>
                            )}

                            {redimir !== null && page === 1 && (
                                <>
                                    <div className="cashback-buttons-cta-form-input-container">
                                        <p>Valor</p>
                                        <input
                                            type="number"
                                            id="valor"
                                            value={valor}
                                            onChange={handleValorChange}
                                            placeholder="Valor"
                                            required
                                        />
                                    </div>
                                    {!redimir && (
                                        <div className="cashback-buttons-cta-form-input-container">
                                            <p>Porcentaje (1-100)</p>
                                            <input
                                                type="number"
                                                id="Porcentaje"
                                                value={porcentaje}
                                                onChange={handlePorcentajeChange}
                                                placeholder="Porcentaje"
                                                required
                                            />
                                        </div>
                                    )}
                                 </>
                            )}
                            {redimir === false && page === 2 && (
                                <>
                                    <div className="cashback-buttons-cta-form-input-container">
                                        <p>Fecha vencimiento</p>
                                        <input
                                            type="date"
                                            id="fecha_vencimiento"
                                            value={fecha}
                                            onChange={(e) => setFecha(e.target.value)}
                                            placeholder="Fecha vencimiento"
                                            min={minDate}  // Set minimum date
                                            max={maxDate}  // Set maximum date
                                            required
                                        />
                                    </div>
                                 </>
                            )}

                            {redimir !== null && (
                                <>
                                    <div className="cashback-buttons-cta-form-input-container">
                                        <button
                                            type="button"
                                            className="cashback-buttons-cta-form-button"
                                            onClick={handleSubmit}
                                        >
                                            {page === 1 ? 'Guardar' : redimir ? 'Redimir cashback' : 'Asignar cashback'}
                                        </button>
                                    </div>
                                 </>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default Cashback;
