import React from 'react';
import './FideyaLogo.scss';

const FideyaLogo = () => {

    return (
        <div className="fideya-logo-container">
                <div className="fideya-logo-container-text">
                    <div className="fideya-logo-container-text-content">
                        <h1>Fide<span>Ya</span></h1>
                        <p>Busca tu comercio favorito</p>
                    </div>
                </div>
            )
        </div>
    );
};

export default FideyaLogo;
