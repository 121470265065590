import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from './requests/requests';
import environment from './enviroment/enviroment';
import { setUsuarioAction } from './actions/get_usuario/set_usuarios';

// Importar Componentes
import Welcome from './pages/welcome/Welcome';
import ForgotPassword from './pages/welcome/ForgotPassword/ForgotPassword';
import CreateAccount from './pages/welcome/CreateAccount/CreateAccount';
import FideyaLogo from './pages/home/FideyaLogo/FideyaLogo';
import Loading from './pages/utils/loading/Loading';
import BasicTemplate from './templates/BasicPage/BasicTemplate';
import MenuTemplate from './templates/MenuTemplate/MenuTemplate';
import HomeTemplate from './templates/HomeTemplate/HomeTemplate';
import Customers from './pages/commerce/custommers/Custommers';
import Benefits from './pages/commerce/Benefits/Benefits';
import CommercePlace from './pages/home/CommercePlace/CommercePlace';
import CreateComerce from './pages/commerce/CreateComerce/CreateComerce';
import Success from './pages/components/success/Success';
import Error from './pages/components/error/Error';
import CustomerCashback from './pages/customerPanel/Cashback/CustomerCashback';

function App() {
    const [loading, setLoading] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const user = useSelector(state => state.usuario);
    const [token, setToken] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setToken(JSON.parse(localStorage.getItem('user'))?.['access_token']);
    
        const validateToken = async () => {
            setLoading(true); // Asegúrate de iniciar la carga antes de hacer la llamada
            try {
                if (token) {
                    const response = await fetchData(
                        environment.api.private_api_url,
                        '/user/get_user',
                        {},
                        '',
                        token
                    );
                    setIsTokenValid(!!response);
                    dispatch(setUsuarioAction(response));
                } else {
                    setIsTokenValid(false);
                }
            } catch (error) {
                console.error('Error validating token:', error);
    
                // Verifica el mensaje de error del token caducado
                if (error.message && error.message.includes('Token has expired')) {
                    localStorage.clear();  // Limpia el localStorage si el token ha expirado
                    setToken(null);        // Asegúrate de reiniciar el token en el estado
                }
                setIsTokenValid(false);
            } finally {
                setLoading(false);
            }
        };
    
        validateToken();
    }, [dispatch, token]);
    

    if (loading) {
        return (
            <div className='screen-loading'>
                <Loading />
            </div>
        );
    }

    return (
        <Router>
            <Routes>
                {/* Ruta de Bienvenida */}
                <Route path="/" element={isTokenValid ? <Navigate to="/home" /> : <BasicTemplate />}>
                    <Route index element={isTokenValid ? <Navigate to="/home" /> : token ? <CreateAccount /> : <Welcome />} />
                    <Route path='forgot_password' element={isTokenValid ? <Navigate to="/home" /> : <ForgotPassword />} />
                    <Route path='create_account' element={isTokenValid ? <Navigate to="/home" /> : <CreateAccount />} />
                </Route>

                {/* Ruta de Comercio */}
                <Route path="/home" element={!!token && !isTokenValid ? <Navigate to="/" /> : <HomeTemplate />}>
                    <Route index element={<FideyaLogo />} />
                    <Route path='commerce_place' element={<CommercePlace />} />
                    <Route path='success' element={<Success />} />
                    <Route path='error' element={<Error />} />
                </Route>

                {/* Ruta de Comercio */}
                <Route path="/commerce" element={isTokenValid && user?.user?.company ? <MenuTemplate /> : <Navigate to="/" />}>
                    <Route index element={user?.user?.company && !user?.commerce ? <Navigate to="/commerce/create_commerce" /> : <Customers />} />
                    <Route path='benefits' element={isTokenValid ? (user?.user?.company && !user?.commerce ? <Navigate to="/commerce/create_commerce" /> : <Benefits />) : <Navigate to="/" />} />
                    <Route path='create_commerce' element={isTokenValid ? (user?.user?.company && user?.commerce ? <Navigate to="/commerce" /> : <CreateComerce />) : <Navigate to="/" />} />
                </Route>

                {/* Ruta de perfil */}
                <Route path="/profile" element={!!token && !isTokenValid ? <Navigate to="/" /> : <MenuTemplate />}>
                    <Route index element={<CustomerCashback />} />
                    {/* <Route path='benefits' element={isTokenValid ? (user?.user?.company && !user?.commerce ? <Navigate to="/commerce/create_commerce" /> : <Benefits />) : <Navigate to="/" />} />
                    <Route path='create_commerce' element={isTokenValid ? (user?.user?.company && user?.commerce ? <Navigate to="/commerce" /> : <CreateComerce />) : <Navigate to="/" />} /> */}
                </Route>

                {/* Ruta para redirigir cualquier otra a la pantalla de bienvenida */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
}

export default App;
