import React, { useEffect, useState } from 'react';
import './Custommers.scss'; // Ajustar la ruta según sea necesario
import searchIcon from '../../../assets/icons/search.svg';
import environment from '../../../enviroment/enviroment';
import { fetchData } from '../../../requests/requests';
import Loading from '../../utils/loading/Loading';
import { useNavigate } from 'react-router-dom';

const Customers = () => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataFiltered, setDataFiltered] = useState([]); // Para almacenar los datos filtrados
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false); // Nuevo estado para controlar si se hizo una búsqueda

    useEffect(() => {
        const fetchDataByType = async () => {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user?.access_token; // Acceso seguro a localStorage
            
            if (!token) {
                console.error('No se encontró el token de acceso');
                return;
            }

            setLoading(true);
            try {
                const response = await fetchData(
                    environment.api.open_api_url,
                    '/customers/get_customers',
                    {},
                    { currentPage, itemsPerPage }, // Paginación pasada aquí
                    token
                );

                console.log(response);
                if (Array.isArray(response.data)) {
                    setData(response.data); // Almacenar los datos
                    setTotalPages(Math.ceil(response.total_count / itemsPerPage)); // Calcular total de páginas correctamente
                } else {
                    console.error('Los datos de la API no son un array:', response.data);
                    setData([]);
                    setTotalPages(0);
                }
            } catch (error) {
                setData([]);
                console.error('Error al obtener los datos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataByType();
    }, [itemsPerPage, currentPage]);

    // Función para realizar la búsqueda
    const searchUser = async () => {
        setLoading(true);
        setIsSearching(true); // Marcar que se ha hecho una búsqueda
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user?.access_token; // Acceso seguro a localStorage

        if (!token) {
            console.error('No se encontró el token de acceso para búsqueda');
            return;
        }

        try {
            const response = await fetchData(
                environment.api.open_api_url,
                '/customers/get_customer',
                {},
                { customer: searchTerm, currentPage, itemsPerPage },
                token
            );
            setDataFiltered(response.data);
            setTotalPages(response.total_pages || 0);
        } catch (error) {
            setDataFiltered([]);
            console.error('Error al realizar la búsqueda:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reiniciar a la primera página al buscar
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reiniciar a la primera página al cambiar elementos por página
    };

    const handleClick = (customer) =>{
        localStorage.setItem('customer', JSON.stringify(customer))
        navigate('/commerce/benefits')
    }

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <div className="customer-template-container">
                    <div className="customer-template-canvas">
                        <div className="customer-template-canvas-title">
                            <h2>Clientes</h2>
                            <form onSubmit={searchUser} className="customer-template-canvas-title-search">
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    placeholder="Buscar..."
                                    aria-label="Buscar clientes"
                                />
                                <img
                                    src={searchIcon}
                                    alt="Buscar"
                                    onClick={searchUser} // Solo se activa al hacer clic
                                    style={{ cursor: 'pointer' }}
                                />
                            </form>
                        </div>
                        <div className="customer-template-canvas-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='display-none'>Nombre</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(isSearching ? dataFiltered : data).map((item) => (
                                        <tr key={item.id}
                                            onClick={() => handleClick(item)}
                                        >
                                            <td className='display-none'>{item.name + ' ' + item.last_name}</td>
                                            <td>{item.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="customer-template-canvas-pagination">
                            {totalPages > 1 && (
                                <div className="customer-template-canvas-pagination-buttons">
                                    <button
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                        className="page-button"
                                        aria-label="Página Anterior"
                                    >
                                        {'<'}
                                    </button>
                                    <p className="pagination-info">
                                        {currentPage} / {totalPages}
                                    </p>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        className="page-button"
                                        aria-label="Página Siguiente"
                                    >
                                        {'>'}
                                    </button>
                                </div>
                            )}
                            <div className="customer-template-canvas-pagination-selector">
                                <select
                                    className="m-0"
                                    id="itemsPerPage"
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Customers;
