import React, { useState } from 'react';
import IconSingle from './IconsSingle/IconSingle';
import './MenuIcon.scss';

const Menu = ({ icons = [] }) => {
    const [activeIcon, setActiveIcon] = useState(null);

    const handleIconClick = (icon) => {
        setActiveIcon(icon.id);

        // Check if the icon has an action property and if it's a function
        if (icon.action && typeof icon.action === 'function') {
            icon.action();
        }
    };

    return (
        <div className="menu-container">
            {icons.map(({ icon, label, id, action }) => (
                <IconSingle
                    key={id}
                    icon={icon}
                    label={label}
                    isActive={activeIcon === id}
                    onClick={() => handleIconClick({ id, action })} // Pass both id and action
                />
            ))}
        </div>
    );
};

export default Menu;
