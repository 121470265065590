import React, { useState } from 'react';
import './Success.scss';

const Success = ({ message = 'Operación exitosa', onAction }) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClick = () => {
        setIsVisible(false);
        if (onAction) {
            onAction();
        }
    };

    return (
        isVisible && (
            <div className="success-container" onClick={handleClick}>
                <div className="success-container-item">
                    <div className="success-container-item-line" />
                    <div className="success-container-item-content">
                        <h3 className='success-container-item-content-title'>¡Operación exitosa!</h3>
                        <p className='success-container-item-content-message'>{message}</p>
                        <p className='success-container-item-content-instruction'>Presiona en cualquier parte para salir</p>
                    </div>
                </div>
            </div>
        )
    );
};

export default Success;
