import React, { useState } from 'react';
import './Error.scss';

const Error = ({ message = 'Operación fallida', onAction }) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClick = () => {
        setIsVisible(false);
        if (onAction) {
            onAction();
        }
    };

    return (
        isVisible && (
            <div className="error-container" onClick={handleClick}>
                <div className="error-container-item">
                    <div className="error-container-item-line">
                        {/* Optional: Add styling or content here */}
                    </div>
                    <div className="error-container-item-content">
                        <h3 className='error-container-item-content-title'>¡Operación fallida!</h3>
                        <p className='error-container-item-content-message'>{message}</p>
                        <p className='error-container-item-content-instruction'>Para salir presiona en cualquier parte</p>
                    </div>
                </div>
            </div>
        )
    );
};

export default Error;
