import React, { useState } from 'react';
import { postData } from '../../requests/requests';
import './Welcome.scss';
import environment from '../../enviroment/enviroment';
import Loading from '../utils/loading/Loading';
import { useNavigate } from 'react-router-dom';
import Error from '../components/error/Error';

const Welcome = () => {

    const navigate = useNavigate()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);


    const getToken = async () => {
        const url = environment.auth0.url_api;
        const data = {
            grant_type: 'password',
            username,
            password,
            audience: environment.auth0.audience,
            scope: 'openid profile email',
            client_id: environment.auth0.client_id,
            client_secret: environment.auth0.client_secret,
        };

        setLoading(true);
        setError(''); // Reset error state before making a request

        try {
            const response = await postData(url, '/oauth/token', data);
            if (response?.access_token) {
                // Assuming setToken is defined elsewhere
                localStorage.setItem('user', JSON.stringify(response));
                localStorage.setItem('user_email', JSON.stringify(username));
                localStorage.setItem('sesionUntill', (new Date().getTime() + response.expires_in).toString())
                navigate(-1)
                window.location.reload()
            } else {
                setError(true)
            }
        } catch (error) {
            setError("Error fetching token: " + (error.message || error));
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username && password) {
            getToken();
        } else {
            setError("Por favor, ingresa tu correo y contraseña.");
        }
    };

    return (
        <div className="welcome-container">
            {loading && <Loading />}
            {error&&<Error message={'Revisa el usuario ó la contraseña e intentalo de nuevo'} ></Error>}
            {!loading && <><div className="welcome-container-text">
          <div className="welcome-container-text-content">
            <h1>Fide<span>Ya</span></h1>
            
            <p>¡Disfruta descuentos y regalos personalizados de tus comercios favoritos al instante! Haz que cada compra sea más emocionante y únete a nuestra comunidad hoy mismo.</p>
            <p onClick={()=>navigate('/home')}  className='welcome-container-text-content-cta'>¡Click aqui  y encuentra tu comercio preferido!</p>
          </div>
        </div><div className="welcome-container-buttons">
            <div className="welcome-container-buttons-cta">
              <form className="welcome-container-buttons-cta-form" onSubmit={handleSubmit}>
                <div className="welcome-container-buttons-cta-form-input">
                  <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder='Correo'
                    required />
                </div>
                <div className="welcome-container-buttons-cta-form-input">
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Contraseña'
                    required />
                </div>
                <button type="submit" className="welcome-container-buttons-cta-form-button">Iniciar sesión</button>
              </form>

              <p className='welcome-container-buttons-cta-forgot-pass'>¿Ha olvidado su contraseña? <span onClick={() => navigate('/forgot_password')} >Click aquí</span></p>

              <button type="button" className="welcome-container-buttons-cta-registrarse" onClick={() => navigate('/create_account')} >Crear cuenta</button>
            </div>
            <p> Crea tu cuenta y <strong>recibe beneficios en un instante</strong></p>
          </div></>}
        </div>
    );
};

export default Welcome;
